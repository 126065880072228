import React from "react"
import styled from "@emotion/styled"

const TextPanel = (props) => {
  const { description } = props

  return (
    <Container>
      <Text>{description}</Text>
    </Container>
  )
}

export default TextPanel

const Container = styled("div")`
  color: white;
  padding: 100px 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 750px) {
    padding: 200px 40px;
  }
`
const Text = styled("p")`
  font-family: "Harbour", sans-serif;
  font-size: 18px;
  margin: 0 auto;
  max-width: 26ch;
  text-align: center;

  @media (min-width: 750px) {
    font-size: 32px;
  }
`
