import React from "react"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { Box, Grid, Link } from "theme-ui"
import Image from "gatsby-image"
import styled from "@emotion/styled"

import helloBadge from "../../images/hello-badge.png"

import Hero from "./Hero"
import PanelEight from "./PanelEight"
import Teaser from "./Teaser"
import Text from "./Text"
import FullImage from "./FullImage"
import CentredImage from "./CentredImage"

const EpisodePanel = styled("div")`
  color: white;
  padding: 100px 40px 50px;
  max-width: 1200px;

  @media (min-width: 750px) {
    margin: 0 auto;
    padding: 200px 40px 0;
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(12, [col-start] 1fr);
  }
`

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      episodeOne: file(relativePath: { eq: "home/panel_02-img-02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      episodeTwo: file(relativePath: { eq: "home/panel_02-img-01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      episodeThree: file(relativePath: { eq: "home/panel_02-img-03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      panelTwoImage: file(relativePath: { eq: "home/am-hp-episodes-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelFourImage: file(relativePath: { eq: "home/am-hp-quality-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelFiveImage: file(
        relativePath: { eq: "home/am-hp-centered-img.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelSixImage: file(
        relativePath: { eq: "home/panel_06-ultra-visuals-img.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelSevenImage: file(
        relativePath: { eq: "home/panel_07-full-img.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      panelSevenImageMobile: file(
        relativePath: { eq: "home/panel_07-full-img-m.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Box bg="#020202">
      <Hero />

      <Box>
        <EpisodePanel>
          <Box sx={{ gridColumn: "col-start 2 / span 10" }}>
            <Grid
              sx={{
                gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
                gridGap: "35px",
              }}
            >
              <Box
                sx={{
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Link as={GatsbyLink} to="/episode-002">
                  <Image fluid={data.episodeTwo.childImageSharp.fluid} />
                </Link>
              </Box>
              <Box
                sx={{
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Link as={GatsbyLink} to="/episode-001">
                  <Image fluid={data.episodeOne.childImageSharp.fluid} />
                </Link>
              </Box>
              <Box
                sx={{
                  borderRadius: 20,
                  overflow: "hidden",
                }}
              >
                <Image fluid={data.episodeThree.childImageSharp.fluid} />
              </Box>
            </Grid>
          </Box>
        </EpisodePanel>
      </Box>

      <Teaser
        buttonLabel="View latest release"
        description="Everything at Adam Monster begins with the story. This is the inspiration for all our creativity, and we build from there to create a truly unique experience."
        image={data.panelTwoImage.childImageSharp.fluid}
        title="episodes"
        to="/episode-002"
      />

      <Text description="Experience truths through the power of unorthodox storytelling." />

      <Teaser
        aligned="right"
        buttonLabel="View the latest"
        description="We deliver only quality workmanship. By providing a longer lasting, high quality product and being committed to ethical fashion, we are undermining the mass market mentality that quicker, faster, cheaper is better. It isn't."
        image={data.panelFourImage.childImageSharp.fluid}
        title="quality"
        to="/episode-002"
      />

      <CentredImage image={data.panelFiveImage.childImageSharp.fluid} />

      <Teaser
        buttonLabel="View the latest"
        description="The visual experience at Adam Monster doesn't give rise to anything in us that isn't already there. It's aim is to simply ignite our curious consciousness, and illuminate who we have always wanted to be."
        image={data.panelSixImage.childImageSharp.fluid}
        title="ultra- visuals"
        to="/episode-002"
      />

      <Box sx={{ position: "relative" }}>
        <FullImage
          desktop={data.panelSevenImage.childImageSharp.fluid}
          mobile={data.panelSevenImageMobile.childImageSharp.fluid}
        />
        <Box
          as="img"
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            width: ["40vw", "25vw"],
            maxWidth: 320,
            transform: "translate(-50%, -50%)",
          }}
          src={helloBadge}
          alt=""
        />
      </Box>

      <PanelEight />
    </Box>
  )
}

export default Home
