import React from "react"
import Image from "gatsby-image"
import styled from "@emotion/styled"

const CentredImagePanel = ({ image }) => {
  return (
    <CentredImage>
      <Image fluid={image} />
    </CentredImage>
  )
}

export default CentredImagePanel

const CentredImage = styled("div")`
  padding: 100px 0;
  max-width: 1200px;

  @media (min-width: 750px) {
    margin: 0 auto;
    padding: 200px 40px;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 35px;
    grid-auto-flow: dense;
  }

  .gatsby-image-wrapper {
    height: 100%;
    grid-column: col-start 2 / span 10;
  }
`
