import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ButtonLink = ({ text, to }) => {
  return (
    <Button to={to}>
      <span>{text}</span>
    </Button>
  )
}

export default ButtonLink

const Button = styled(Link)`
  align-self: flex-start;
  border: 1px solid #ee3245;
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 2.5rem 12px;
  transition: background-color 0.3s ease-out;
  border-radius: 100px;

  @media (min-width: 1000px) {
    font-size: 16px;
  }

  :hover {
    background-color: #ee3245;
  }

  span {
    display: inline-block;
  }
`
