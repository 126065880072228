import styled from "@emotion/styled"

export const Teaser = styled("div")`
  color: white;
  padding: 50px 40px;
  max-width: 1200px;

  @media (min-width: 750px) {
    margin: 0 auto;
    padding: 200px 40px;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 35px;
    grid-auto-flow: dense;
  }
`

export const Image = styled("div")`
  border-radius: 20px;
  overflow: hidden;

  @media (min-width: 750px) {
    grid-column: col-start 2 / span 6;

    &.right {
      grid-column: col-start 6 / span 6;
    }
  }
`

export const Content = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 750px) {
    grid-column: col-start 8 / span 4;

    &.right {
      grid-column: col-start 2 / span 4;
    }
  }
`

export const Title = styled("h2")`
  font-size: 60px;
  margin-top: -24px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1;
  width: 300px;

  @media (min-width: 750px) {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 90px;
    direction: rtl;
    width: auto;

    &.right {
      direction: ltr;
    }
  }

  @media (min-width: 1000px) {
    font-size: 124px;
  }
`

export const Description = styled("p")`
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.8;

  @media (min-width: 750px) {
    margin-bottom: 40px;
  }
`
