import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { Box, Flex } from "theme-ui"
import styled from "@emotion/styled"

import heroGraphic from "../../../images/home/am-hero_graphic.svg"

const Hero = () => {
  const { desktopImage, mobileImage } = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "home/panel_01-full-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobileImage: file(relativePath: { eq: "home/panel_01-full-img-m.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 64em)`,
    },
  ]

  return (
    <Box sx={{ position: "relative" }}>
      <Image fluid={sources} />
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          as="img"
          sx={{ width: ["90vw", "60vw"], mb: ["10vw", "5vw"] }}
          src={heroGraphic}
          alt=""
        />
        <Button to="/episode-002">
          <span>Shop now</span>
        </Button>
      </Flex>
    </Box>
  )
}

const Button = styled(Link)`
  border: 1px solid white;
  color: #111111;
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 5em 12px;
  border-radius: 100px;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out,
    color 0.3s ease-out;

  @media (min-width: 1000px) {
    font-size: 16px;
  }

  :hover {
    background-color: #ee3245;
    border-color: #ee3245;
    color: white;
  }

  span {
    display: inline-block;
  }
`

export default Hero
