import React from "react"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"

const ContentPanel = () => (
  <Panel>
    <PanelInner>
      <Fade bottom>
        <Info>
          <span>We are all connected</span>
          <h3>Interconnection</h3>
          <p>
            Most brands start with a product and build a story around it. At
            Adam Monster the story builds the brand around it, the designs
            evolving as the story reveals itself. A story that needs to be
            shared, resulting in a bespoke product.
          </p>
        </Info>

        <Info>
          <span>More is less</span>
          <h3>Experience</h3>
          <p>
            A big part of our mission is to provide a truly unique experience.We
            believe that you are truly unique, and that a considered, ethical
            product helps our community be mindful about what they consume.Adam
            Monster does not mass produce.Our products last.
          </p>
        </Info>

        <Info>
          <span>Real information</span>
          <h3>Transparency</h3>
          <p>
            It's rare for apparel companies to reveal exactly how and what goes
            into a product. At Adam Monster we believe in the power of knowledge
            and the importance of informed decisions. We have nothing to hide so
            you can have confidence in your purchase.
          </p>
        </Info>

        <Info>
          <span>Beauty for a reason</span>
          <h3>Creativity</h3>
          <p>
            Adam Monster was born to prove the importance of creativity in
            business. At Adam Monster we know that data is not the answer to
            every question and our focus is on solving problems with creativity
            and through storytelling.
          </p>
        </Info>
      </Fade>
    </PanelInner>
  </Panel>
)

const Panel = styled("div")`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;

  @media (min-width: 1000px) {
    padding: 200px 0;
  }
`

const PanelInner = styled("div")`
  display: grid;
  grid-column-gap: 8vw;
  grid-row-gap: 4vw;
  grid-template-columns: 1fr;
  padding: 0 30px;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    width: 65vw;
  }
`

const Info = styled("div")`
  color: #fff;

  span {
    color: #c5d7b0;
    font-family: "Harbour", sans-serif;
    font-size: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (min-width: 1000px) {
      font-size: 12px;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: normal;
    line-height: 1;
    margin: 0 0 15px;

    @media (min-width: 1000px) {
      font-size: 44px;
      margin: 0 0 30px;
    }
  }
`

export default ContentPanel
