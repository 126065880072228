import React from "react"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import ButtonLink from "../../ButtonLink"
import * as S from "./styles"

const Teaser = (props) => {
  const { aligned, buttonLabel, description, image, title, to } = props

  return (
    <S.Teaser>
      <S.Image className={aligned}>
        <Img fluid={image} />
      </S.Image>
      <S.Content className={aligned}>
        <Fade bottom>
          <S.Title className={aligned}>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <ButtonLink text={buttonLabel} to={to} />
        </Fade>
      </S.Content>
    </S.Teaser>
  )
}

Teaser.defaultProps = {
  aligned: "left",
}

export default Teaser
